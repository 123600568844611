import './style/style.css';
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import LoginWhatsApp from './login/login';
import CreateMessage from './createMessage/createMessage';
import { messages } from '../../services/messages';

const WhatsAppWebHome = ({
  setShowFormWhatsApp,
  showFormWhatsApp,
  onClose,
  isAdmin,
  isGteConcesion,
  socket,
}) => {
  const [active, setActive] = useState(true);
  const [qrCodeURL, setQRCodeURL] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [chatGroups, setChatGroups] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [clientReady, setClientReady] = useState(false);
  const [spinTipText, setSpinTipText] = useState('Generando QR...');
  const [spinTipTextWhatModal, setSpinTipTextWhatModal] = useState('Generando QR...');

  const getGroupChat = async () => {
    try {
      const response = await messages.getGroupChats();
      setLoadingForm(false);
      return setChatGroups(response);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const getQRData = (newCode) => {
    setShowQR(true);
    setQRCodeURL(newCode);
    setLoading(false);
    setActive(false);
  };

  useEffect(() => {
    socket.on('qrCode', (newCode) => {
      getQRData(newCode);
    });

    socket.on('ready', () => {
      setShowQR(false);
      setLoading(false);
      setClientReady(true);
    });

    socket.on('closeSession', (session) => {
      if (session) {
        setClientReady(false);
        setLoading(true);
        setSpinTipTextWhatModal('Cierre de sesión en curso...');
      }
    });
  }, []);

  useEffect(() => {
    if (!clientReady) {
      socket.emit('initialize-wsp');
    }

    if (clientReady) {
      console.log('gruposss');
      console.log(clientReady, 'clientReady despues del if');
      setSpinTipText('Buscando grupos...');
      setLoadingForm(true);

      getGroupChat();
    }
  }, [clientReady]);


  const updateChatGroups = () => {
    setSpinTipText('Buscando grupos...');
    setLoadingForm(true);
    getGroupChat();
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="display-justify-center-form-whats-btn">
        <Spin tip={spinTipTextWhatModal} spinning={loading} />
        {clientReady ? (
          <Spin tip={spinTipText} spinning={loadingForm}>
            <div>
              <CreateMessage
                chatGroups={chatGroups}
                socket={socket}
                updateChatGroups={updateChatGroups}
                onClose={onClose}
                setLoading={setLoading}
                setSpinTipText={setSpinTipText}
                setClientReady={setClientReady}
                setSpinTipTextWhatModal={setSpinTipTextWhatModal}
                setLoadingForm={setLoadingForm}
                isAdmin={isAdmin}
                isGteConcesion={isGteConcesion}
              />
            </div>
          </Spin>
        ) : (
          <div>{showQR && <LoginWhatsApp qrCodeURL={qrCodeURL} active={active} />}</div>
        )}
      </Col>
    </Row>
  );
};
export default WhatsAppWebHome;
