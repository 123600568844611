import currency from 'currency.js';
import { omit } from 'lodash';
import { cartTypes } from '../constants';

const initialState = {
  currency: 'MXN',
  cartDetail: [],
  dock: 0,
  total: 0,
  // saldo: null,
  // Solo si existe saldo, fue un pago parcial
  // payments: null,
};
const cartReducer = (state = initialState, action) => {
  if (action.type === cartTypes.ADD_TO_CART) {
    const { product } = action;

    // Solo un item por code
    const existedItemPackege = state.cartDetail.find((item) => {
      return product.code === item.code;
    });
    if (existedItemPackege) {
      return state;
    }

    // Si ya existe un paquete no puedes meter otro item
    const validatePackege = state.cartDetail.find((item) => {
      if (item.product_type == 'paquetes') {
        return item.tourName;
      }
    });
    if (validatePackege) {
      // Lista de IDs permitidos
      if (action.product.id_proveedor !== 214) {
        alert('No puedes mezclar otros items con un paquete');
        return state;
      }
    }

    // Si ya existe un item  no puedes meter un paquete
    const validateItem = state.cartDetail.find((item) => {
      if (item.product_type != 'paquetes') {
        return item.tourName;
      }
    });
    if (validateItem && action.product.product_type == 'paquetes') {
      alert('No puedes mezclar otros items con un paquete');
      return state;
    }

    // VALIDACION DE MAXIMO 3 SERVICIOS DE XCARET PUEDES TENER EN EL CARRITO
    let countItemsXcaret = state.cartDetail.reduce((count, item) => {
      return item.id_proveedor === 182 ? count + 1 : count;
    }, 0);
    if (action.product && action.product.id_proveedor === 182) {
      countItemsXcaret += 1;
    }
    if (countItemsXcaret > 3) {
      alert('Maximo puedes tener 3 actividades del proveedor Xcaret');
      return state;
    }

    const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
      .add(currency(product.totalTour, { precision: 4 }))
      .format();
    return {
      ...state,
      cartDetail: [...state.cartDetail, product],
      total: newTotal,
    };
  }

  if (action.type === cartTypes.ADD_TO_DOCK) {
    const { product } = action;
    if (product.total_dock > 0) {
      const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
        .add(currency(product.total_dock, { precision: 4 }))
        .format();
      const newDock = state.dock + parseFloat(product.total_dock);
      return {
        ...state,
        dock: newDock,
        total: newTotal,
      };
    }
  }

  if (action.type === cartTypes.UPDATE_ITEM) {
    const { product } = action;
    const nuevosDatos = product;
    const viejosDatos = state.cartDetail;
    let totalAdd = 0;
    let totalSub = 0;
    let newDock = 0;
    const itemToSearch = viejosDatos.find((item) => nuevosDatos.code === item.code);
    if (itemToSearch) {
      totalAdd = parseFloat(nuevosDatos.totalTour);
      totalSub = parseFloat(itemToSearch.totalTour);
      const nuevoCartDetail = viejosDatos.map((registro) => {
        if (registro.code === nuevosDatos.code) {
          return { ...registro, ...nuevosDatos };
        }
        return registro;
      });
      // Actualizamos el total muelle
      if (itemToSearch.product_type == 'actividades') {
        totalAdd = totalAdd + parseFloat(nuevosDatos.total_dock ? nuevosDatos.total_dock : 0);
        totalSub = totalSub + parseFloat(itemToSearch.total_dock ? itemToSearch.total_dock : 0);
        newDock = state.dock - parseFloat(itemToSearch.total_dock ? itemToSearch.total_dock : 0);
        newDock = newDock + parseFloat(nuevosDatos.total_dock ? nuevosDatos.total_dock : 0);
      }
      if (itemToSearch.product_type == 'paquetes_') {
        let valDockAdd = 0;
        let valDockSub = 0;
        nuevosDatos.productos.forEach((element) => {
          if (element.muelle && element.total_dock > 0) {
            valDockAdd = valDockAdd + parseFloat(element.total_dock ? element.total_dock : 0);
          }
        });
        itemToSearch.productos.forEach((element) => {
          if (element.muelle && element.total_dock > 0) {
            valDockSub = valDockSub + parseFloat(element.total_dock ? element.total_dock : 0);
          }
        });
        totalAdd = totalAdd + parseFloat(valDockAdd);
        totalSub = totalSub + parseFloat(valDockSub ? valDockSub : 0);
        newDock = state.dock - parseFloat(valDockSub ? valDockSub : 0);
        newDock = newDock + parseFloat(valDockAdd ? valDockAdd : 0);
      }
      // console.log(newDock);
      // Actualizamos el total
      const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
        .subtract(currency(totalSub, { precision: 4 }))
        .add(currency(totalAdd, { precision: 4 }))
        .format();
      return {
        ...state,
        cartDetail: nuevoCartDetail,
        total: newTotal,
        dock: newDock,
      };
    } else {
      const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
        .add(currency(product.totalTour, { precision: 4 }))
        .format();
      return {
        ...state,
        cartDetail: [...state.cartDetail, product],
        total: newTotal,
      };
    }
  }

  if (action.type === cartTypes.REMOVE_ITEM) {
    console.log(action.data);
    const { code } = action.data;
    let updateDock = false;
    const itemToRemove = state.cartDetail.find((item) => code === item.code);
    const newItems = state.cartDetail.filter((item) => item.code !== code);
    const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
      .subtract(currency(itemToRemove.totalTour, { precision: 4 }))
      .format();
    if (newItems.length === 0 && state.cupon_aplicado) {
      return {
        ...omit(state, 'cupon_aplicado'),
        cartDetail: newItems,
        total: 0,
        dock: 0,
      };
    }
    if (
      itemToRemove.showDock &&
      itemToRemove.total_dock > 0 &&
      itemToRemove.product_type == 'actividades'
    ) {
      let newDock = state.dock - parseFloat(itemToRemove.total_dock);
      const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
        .subtract(currency(itemToRemove.totalTour, { precision: 4 }))
        .subtract(currency(itemToRemove.total_dock, { precision: 4 }))
        .format();
      updateDock = true;
      return {
        ...state,
        cartDetail: newItems,
        total: newTotal,
        dock: newDock,
      };
    }
    if (itemToRemove.product_type == 'paquetes_') {
      let newDock = 0;
      itemToRemove.productos.forEach((element) => {
        if (element.muelle && element.total_dock != '') {
          newDock += parseFloat(element.total_dock);
        }
      });
      const newTotalDock = state.dock - newDock;
      const newTotal = currency(state.total, { formatWithSymbol: true, precision: 4 })
        .subtract(currency(itemToRemove.totalTour, { precision: 4 }))
        .subtract(currency(newDock, { precision: 4 }))
        .format();
      updateDock = true;
      if (newDock > 0) {
        return {
          ...state,
          cartDetail: newItems,
          total: newTotal,
          dock: newTotalDock,
        };
      }
    }

    if (!updateDock) {
      return {
        ...state,
        cartDetail: newItems,
        total: newTotal,
      };
    }
  }

  if (action.type === cartTypes.REMOVE_DOCK) {
    console.log(action.data);
    const { code } = action.data;
    const itemToRemove = state.cartDetail.find((item) => code === item.code);
    console.log('REMOVE_DOCK itemToRemove...');
    console.log(itemToRemove);
    console.log(itemToRemove.total);
    console.log(itemToRemove.total_dock);
    // let resDock = state.dock - itemToRemove.total_dock;
    // if (resDock > 0) {
    //   return {
    //     ...state,
    //     dock: resDock,
    //   };
    // }
  }

  if (action.type === cartTypes.HAS_PENDING_PAYMENT) {
    return {
      ...state,
      saldo: { amount: action.data.amount, reservation: action.data.reserva },
    };
  }
  if (action.type === cartTypes.CHECKOUT_REQUEST) {
    return initialState;
  }
  if (action.type === cartTypes.CLEAR_CART) {
    return {
      ...omit(state, 'cupon_aplicado', 'saldo'),
      cartDetail: [],
      total: 0,
      dock: 0,
      // saldo: null,
    };
  }
  if (action.type === 'CHANGE_CURRENCY') {
    return { ...state, currency: action.currency };
  }
  if (action.type === cartTypes.CHECKOUT_UPDATE_TOTAL) {
    const { total, code, carrito } = action.data;
    const cupon_aplicado = code || null;
    if (carrito) {
      return {
        ...state,
        cartDetail: [...carrito],
        cupon_aplicado,
        total: currency(total, { formatWithSymbol: true, precision: 4 }).format(),
      };
    }
    return {
      ...state,
      cupon_aplicado,
      total: currency(total, { formatWithSymbol: true, precision: 4 }).format(),
    };
  }
  if (action.type === cartTypes.CHECKOUT_FAILURE) {
    return action.cart;
  }
  // NOTA Si es necesario agrega un action para cambiar la suma o de editar item
  // INSIDE CART COMPONENT
  // if (action.type === ADD_QUANTITY) {
  //   const addedItem = state.items.find(item => item.id === action.id);
  //   addedItem.quantity += 1;
  //   const newTotal = state.total + addedItem.price;
  //   return {
  //     ...state,
  //     total: newTotal,
  //   };
  // }
  // if (action.type === SUB_QUANTITY) {
  //   const addedItem = state.items.find(item => item.id === action.id);
  //   if (addedItem.quantity === 1) {
  //     const newItems = state.addedItems.filter(item => item.id !== action.id);
  //     const newTotal = state.total - addedItem.price;
  //     return {
  //       ...state,
  //       addedItems: newItems,
  //       total: newTotal,
  //     };
  //   }
  //   addedItem.quantity -= 1;
  //   const newTotal = state.total - addedItem.price;
  //   return {
  //     ...state,
  //     total: newTotal,
  //   };
  // }
  return state;
};

export default cartReducer;
