import { Button, Col, Popconfirm, Row, Tooltip } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { AntSelect, AntTextArea } from '../../../components/AntFields/AntFields';
import openNotification from '../../../components/Notificaciones';
import { LogoutOutlined, SyncOutlined } from '@ant-design/icons';
import { messages } from '../../../services/messages';

const CreateMessage = ({
  chatGroups,
  socket,
  updateChatGroups,
  onClose,
  setLoadingForm,
  setLoading,
  setClientReady,
  setSpinTipText,
  setSpinTipTextWhatModal,
  isAdmin,
  isGteConcesion
}) => {
  const schema = Yup.object().shape({
    groupId: Yup.string().required('Requerido').nullable(),
    message: Yup.string().required('Requerido').nullable(),
  });

  const handleSubmit = async (values) => {
    console.log(values, 'values');
    setLoadingForm(true);
    setSpinTipText('Enviando mensaje...');
    try {
      const response = await messages.createWhatsappMessage(values);
      openNotification('success', 'WhastApp enviado', true);
    } catch (error) {
      openNotification('error', `WhatsApp no enviado, ${error}`, true);
    }
    setLoadingForm(false);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const doLogOut = async () => {
    setLoading(true);
    setClientReady(false);
    socket.emit('doLogout');
    setSpinTipTextWhatModal('Cerrando sesión... No cierre la ventana');
    try {
      const response = await messages.doWhatsappLogout();
      console.log(response, 'response logout');
      openNotification('success', 'Sesión cerrada con exito', true);
      setSpinTipTextWhatModal('Cargando...');
      onClose();
    } catch (error) {
      console.log(error, 'error logout');
      openNotification('error', `La sesión no se pudo cerrar, error:${error}`, true);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          groupId: null,
          message: null,
        }}
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {() => (
          <Form>
            <Row>
              <Col xs={12} sm={10} md={10} lg={10}>
                <Field
                  name="groupId"
                  size="large"
                  label="Grupo de WhatsApp"
                  placeholder="Selecciona un grupo de WhatsApp"
                  component={AntSelect}
                  selectOptions={() =>
                    chatGroups?.map((item) => (
                      <Option
                        label={item?.name}
                        key={item.id?._serialized}
                        value={item.id?._serialized}
                      >
                        {item.name}
                      </Option>
                    ))
                  }
                />
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} className="display-align-center-justify-end">
                <Tooltip title="Actualizar chat de grupos">
                  <Button icon={<SyncOutlined />} onClick={updateChatGroups} size="large" />
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Field
                  type="text"
                  name="message"
                  size="large"
                  label="Mensaje"
                  placeholder="Escribe el mensaje"
                  component={AntTextArea}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Button onClick={onClose} danger>
                      Cancelar mensaje
                    </Button>
                  </Col>
                  <Col className="display-justify-end" xs={12} sm={6} md={6} lg={6}>
                    <Button type="primary" htmlType="submit">
                      Enviar mensaje
                    </Button>
                  </Col>
                </Row>
              </Col>
              {(isGteConcesion || isAdmin) && (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Row style={{ paddingTop: '1rem' }}>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <Popconfirm
                        title="¿Esta seguro de cerrar esta sesión?"
                        description="Si cierra esta sesión, se debe scanear el nuevo el QR"
                        onConfirm={doLogOut}
                        onCancel={cancel}
                        okText="Si"
                        cancelText="No"
                      >
                        <Button icon={<LogoutOutlined />}>Cerrar sesión</Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default CreateMessage;
