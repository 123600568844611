import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider, message } from 'antd';
import es_Es from 'antd/es/locale/es_ES';
import moment from 'moment';
import { App } from './pages/App';
import store, { persistor } from './store/store';
import { history } from './store/history';
import 'moment/locale/es';

import openNotification from './components/Notificaciones';
import SocketContext from './components/Context/socketContext';
import io from 'socket.io-client';
import { some } from 'lodash';
import { messages } from './services/messages';
import { loginTypes } from './constants';

const env = process.env.NODE_ENV === 'development';

let socket = io('https://socket-server-message.onrender.com');

// let socket = io('http://localhost:5000/');

const data = {
  socket: socket,
};

socket.on('msj_all', (info) => {
  openNotification('success', info.title, info.texto, null);
});

const sendMessage = async (info) => {
  let states = store.getState();
  let user = states.login.user;
  const data = {
    id_user: user.user_id,
  };
  try {
    const response = await messages.getCountUnreadedMessage(data);
    const unreaded = response.data;
    store.dispatch({ type: loginTypes.COUNT_UNREADED_MESSAGE, unreaded });

    //const isSendMessage = some(user.rolesNuevos, ['id', info.id_main_role]);
    const isSendMessage = some(info.id_main_role, (id) => {
      return some(user.rolesNuevos, ['id', id])
    })
    console.log(isSendMessage);
    if (isSendMessage) {
      openNotification('success', 'Nuevo mensaje recibido', null);
    }
  } catch (error) {
    throw error;
  }
};

socket.on('correo_all', (info) => {
  sendMessage(info);
});

moment.locale('es');
render(
  <PersistGate loading={null} persistor={persistor}>
    <SocketContext.Provider value={data}>
      <Provider store={store}>
        <Router history={history}>
          <ConfigProvider locale={es_Es}>
            <App />
          </ConfigProvider>
        </Router>
      </Provider>
    </SocketContext.Provider>
  </PersistGate>,
  document.getElementById('root')
);
if (module.hot) {
  module.hot.accept();
}
